import moment from "moment";
import { v4 as getNewGUID } from "uuid";

const propMap = [
  "headcountRequestID",
  "organizationID",
  "usageTypeID",
  "positionRoleID",
  "departmentID",
  "sectionID",
  "areaID",
  "shiftID",
  "prevID",
  "quantity",
  "schedulable",
  "billable",
  "companyID",
  "startDate",
  "endDate",
  "headCountGrouping",
];

const CCR_STATUS = Object.freeze({
  IN_PROGRESS: 1,
  SUBMITTED: 2,
  FOR_REVIEW: 3,
  APPROVED: 4,
  DENIED: 5,
});

export const getKeyValuesFromApiData = (data) => {
  let assets = {};
  let companies = {};
  let usageTypes = {};
  let organizations = {};
  let positionRoles = {};
  let departments = {};
  let sections = {};
  let areas = {};
  let shifts = {};
  // Load assets, organizations, departments, sections & areas
  data.sites.data.forEach((asset) => {
    assets[asset.assetID] = asset.name;
    asset.organizations.forEach((organization) => {
      organizations[organization.organizationID] = organization.name;
      organization.departments.forEach((department) => {
        departments[department.departmentID] = department.name;
        department.sections.forEach((section) => {
          sections[section.sectionID] = section.name;
          section.areas.forEach((area) => {
            areas[area.areaID] = area.name;
          });
        });
      });
      organization.organizationPositionRoles.forEach((orgPos) => {
        positionRoles[orgPos.positionRole.positionRoleID] =
          orgPos.positionRole.name;
      });
    });
  });

  // Load usage types
  data.usageTypes.data.forEach((usageType) => {
    usageTypes[usageType.usageTypeID] = usageType.name;
  });

  // Load companies
  data.companies.data.forEach((company) => {
    companies[company.companyID] = company.name;
  });

  data.shifts.data.forEach((shift) => {
    shifts[shift.shiftID] = shift.name;
  });

  return {
    assets,
    companies,
    usageTypes,
    organizations,
    positionRoles,
    departments,
    sections,
    areas,
    shifts,
  };
};

export const getHeadcountsFromApiData = (current, lastApproved = null) => {
  let headcounts = [];
  if (!current) return headcounts;

  current.headcountRequests.forEach((hcReq) => {
    let headcount = {};
    propMap.forEach((attr) => {
      if (attr.includes("Date") || attr.includes("date"))
        _.assign(headcount, { [attr]: moment(_.get(hcReq, attr)) });
      _.assign(headcount, { [attr]: _.get(hcReq, attr) });
    });
    headcount["status"] = getHeadcountStatus(
      hcReq,
      current.ccrStatus.ccrStatusID,
      lastApproved?.headcountRequests
    );
    headcounts.push(headcount);
  });

  return headcounts;
};

const getHeadcountStatus = (hcReq, ccrStatus, lastApprovedHcReqs) => {
  if (ccrStatus === CCR_STATUS.APPROVED) return "Current";

  if (!lastApprovedHcReqs) {
    if (ccrStatus !== CCR_STATUS.DENIED) return "Current";
    else return "Denied";
  }

  if (!hcReq.prevID) return "Denied";

  const oldHcReq = lastApprovedHcReqs.filter(
    (x) => x.headcountRequestID === hcReq.prevID
  )[0];
  if (!oldHcReq) return "Denied";

  let status = "Current";
  propMap.forEach((key) => {
    if (
      hcReq.hasOwnProperty(key) &&
      !["prevID", "headcountRequestID"].includes(key) &&
      _.get(hcReq, key) !== _.get(oldHcReq, key)
    )
      status = "Denied";
  });

  return status;
};

export const getHeadcountClone = (hc) => {
  let newHC = deepCopyUsingJSON(hc);
  const tomorrow = moment().add(1, "days");

  newHC.headcountRequestID = getNewGUID();
  newHC.status = "New";
  newHC.prevID = 0;

  if (newHC.startDate) {
    if (moment(newHC.startDate) < tomorrow)
      newHC.startDate = tomorrow.format("YYYY-MM-DD");
  }
  if (newHC.endDate) {
    if (moment(newHC.endDate) < tomorrow)
      newHC.endDate = tomorrow.format("YYYY-MM-DD");
  }

  return newHC;
};

export const getNewHeadcount = (referenceData) => {
  const { shifts } = referenceData;
  let newHC = {};
  newHC.headcountRequestID = getNewGUID();
  newHC.status = "New";
  newHC.startDate = moment().add(1, "d").startOf("day");
  newHC.shiftID = Number(Object.entries(shifts)[0][0]);
  newHC.organizationID = Number(0);
  newHC.companyID = Number(0);
  newHC.usageTypeID = Number(0);
  newHC.quantity = 0;
  newHC.billable = false;
  newHC.schedulable = false;
  newHC.headCountGrouping = null;
  return newHC;
};

export const updateExistingHeadcount = (hc, editedHC) => {
  propMap.forEach((prop) => {
    _.assign(hc, { [prop]: _.get(editedHC, prop) });
  });
  hc.startDate = editedHC.startDate.moment
    .startOf("day")
    .format("YYYY-MM-DDTHH:mm:ss");
  hc.endDate =
    editedHC.endDate && editedHC.endDate.moment
      ? editedHC.endDate.moment.startOf("day").format("YYYY-MM-DDTHH:mm:ss")
      : null;

  if (hc.status !== "New") hc.status = "Edited";

  return { ...hc };
};

export const getHeadcountByID = (headcounts, id) =>
  headcounts.filter((x) => x.headcountRequestID === id)[0];
export const getFilteredHeadcountByID = (headcounts, id) =>
  headcounts.filter((x) => x.headcountRequestID !== id);

/**
 * This method deep copies only simple objects, it can traverse complex hierarchies but cannot copy date objects
 */
export const deepCopyUsingJSON = (obj) => JSON.parse(JSON.stringify(obj));
