import React, { useState, useMemo, useRef, useEffect } from "react";
import * as Logic from "./ManageHeadcounts.logic";
import Dropdown from "../../../presentation/Input/Dropdown";
import { Button } from "reactstrap";
import DisplayHeadcountCard from "./HeadcountCard/DisplayHeadcountCard";
import * as Actions from "../../../../redux/actions/manageHeadcountActions";
import styles from "./ManageHeadcounts.module.scss";
import ToggleButton from "../../../presentation/Input/ToggleButton";
import CollapseableSection from "../../../presentation/CollapseableSection/CollapseableSection";
import {
  getRenderableHeadcounts,
  getIsEditingHeadcount,
  getCurrentlyCreatingNew,
  getCurrentCloneFromId,
} from "../../../../redux/selectors/manageHeadcount";
import { connect } from "react-redux";
import { useHistory } from "react-router-dom";
import { Alert } from "reactstrap";
import EditHeadcountCard from "./HeadcountCard/EditHeadcountCard";
import IconOnlyButton from "../../../presentation/unity/IconOnlyButton";
import { quitCcr } from "../../../../redux/actions/editCcrActions";
import WizardButtonBar from "../../Nav/Wizard/WizardButtonBar";
import UnityButton from "../../../presentation/unity/Button";
import DisplayGroupedHeadcountCard from "./HeadcountCard/DisplayGroupedHeadcountCard";
import EditGroupedHeadcountCard from "./HeadcountCard/EditGroupedHeadcountCard";

const ManageHeadcounts = (props) => {
  const { headcountRequests, currentlyEditingHeadcount, currentCloneFromId } =
    props;
  console.log("🚀 ~ ManageHeadcounts ~ headcountRequests:", headcountRequests);

  const [GroupSortConfig, setGroupSortConfig] = useState(
    Logic.getDefaultConfig
  );

  const myArr = Logic.arrayGroupBy(headcountRequests, "headCountGrouping");
  console.log("🚀 ~ ManageHeadcounts ~ myArr:", myArr);

  const curatedHeadcountRequests = useMemo(
    () =>
      Logic.getCuratedHeadcountRequests(
        headcountRequests,
        GroupSortConfig,
        currentlyEditingHeadcount,
        currentCloneFromId
      ),
    [
      headcountRequests,
      GroupSortConfig,
      currentlyEditingHeadcount,
      currentCloneFromId,
    ]
  );

  const validationState = Logic.validateHeadcounts(headcountRequests);
  const disableRenderControls = Boolean(currentlyEditingHeadcount);
  const history = useHistory();
  const editCard = useRef(null);

  /**
   * The deafult open state uses an integer to keep track of actions.
   * Eg; If the user clicks the expand all more than once,
   * if any of the sections have been manually closed they won't open again because the props would not have changed.
   */
  const [SectionDefaultOpen, setSectionDefaultOpen] = useState(0);

  useEffect(() => {
    if (editCard.current && !props.currentlyCreatingNew)
      editCard.current.scrollIntoView({ behavior: "smooth", block: "center" });
  }, [props.currentlyEditingHeadcount, props.currentlyCreatingNew]);

  return (
    <div>
      <div className={styles.controls_container}>
        <div className={styles.add_button}>
          <Button
            outline
            color="primary"
            disabled={disableRenderControls}
            onClick={() => props.createHeadcount()}
          >
            Add New Headcount
          </Button>
        </div>
        <div className={styles.group_sort_controls}>
          <div>
            <IconOnlyButton
              title="Expand All"
              btnClass={styles.expand_all_button}
              icon="maximize"
              disabled={disableRenderControls}
              onClick={() =>
                setSectionDefaultOpen(Math.abs(SectionDefaultOpen) + 1)
              }
            />
            <IconOnlyButton
              title="Collapse All"
              btnClass={styles.expand_collapse_button}
              icon="minimize"
              disabled={disableRenderControls}
              onClick={() =>
                setSectionDefaultOpen((Math.abs(SectionDefaultOpen) + 1) * -1)
              }
            />
          </div>
          <div className={styles.dropdown_container}>
            Group By
            <Dropdown
              items={Logic.getSectionOptions}
              value={GroupSortConfig.group}
              onChange={(e) =>
                setGroupSortConfig({
                  ...GroupSortConfig,
                  group: e.target.value,
                })
              }
              disabled={disableRenderControls}
            />
          </div>
          <div className={styles.dropdown_container}>
            Sort By
            <Dropdown
              items={Logic.getSortOptions}
              value={GroupSortConfig.sort}
              showNoneOption
              onChange={(e) =>
                setGroupSortConfig({ ...GroupSortConfig, sort: e.target.value })
              }
              disabled={disableRenderControls}
            />
          </div>
          <div>
            <ToggleButton
              name={"DateSort"}
              options={["Start Date", "End Date"]}
              onChange={(value) =>
                setGroupSortConfig({ ...GroupSortConfig, date_sort: value })
              }
              selected={GroupSortConfig.date_sort}
              disabled={disableRenderControls}
            />
          </div>
        </div>
      </div>
      <div className={styles.headcountcard_container}>
        {curatedHeadcountRequests.map((section) => {
          console.log("🚀 ~ {curatedHeadcountRequests.map ~ section:", section);
          const groupedHeadcountRequests = Logic.arrayGroupBy(
            section.values,
            "headCountGrouping"
          );
          console.log(
            "🚀 ~ {curatedHeadcountRequests.map ~ mySecondArr:",
            groupedHeadcountRequests
          );
          const nonGroupedHeadcountRequests = section.values.filter(
            (x) => Boolean(x?.headCountGrouping) === false
          );
          const filteredGroupedHeadcountReqs = groupedHeadcountRequests.filter(
            (x) => Boolean(x.key)
          );
          console.log(
            "🚀 ~ filteredGroupedHeadcountReqs:",
            filteredGroupedHeadcountReqs
          );
          return (
            <CollapseableSection
              key={section.key || "none"}
              title={section.key || "Unknown"}
              forceOpen={section.editing}
              defaultOpen={section.key ? SectionDefaultOpen : true}
              disableToggle={disableRenderControls}
            >
              {nonGroupedHeadcountRequests.map((hcr) =>
                currentlyEditingHeadcount === hcr.headcountRequestID ? (
                  <div ref={editCard} key={hcr.headcountRequestID}>
                    <EditHeadcountCard headcount={hcr} />
                  </div>
                ) : (
                  <DisplayHeadcountCard
                    key={hcr.headcountRequestID}
                    headcount={hcr}
                    disableButtons={disableRenderControls}
                  />
                )
              )}
              {Boolean(filteredGroupedHeadcountReqs.length) &&
                filteredGroupedHeadcountReqs.map((groupedSection) => {
                  const baseHeadcount = groupedSection.values[0];
                  if (
                    currentlyEditingHeadcount ===
                    baseHeadcount.headcountRequestID
                  ) {
                    return (
                      <div key={baseHeadcount.headcountRequestID}>
                        <EditGroupedHeadcountCard
                          headcount={baseHeadcount}
                          disableButtons={disableRenderControls}
                          projectedDates={groupedSection.values.map(
                            ({ headcountRequestID, startDate, endDate }) => ({
                              headcountRequestID,
                              startDate,
                              endDate,
                            })
                          )}
                        />
                      </div>
                    );
                  }
                  return (
                    <div>
                      <DisplayGroupedHeadcountCard
                        key={baseHeadcount.headcountRequestID}
                        baseHeadcount={baseHeadcount} // all of these headcounts have the same info, so just need one
                        disableToggle={disableRenderControls}
                        projectedDates={groupedSection.values.map(
                          (headcountReqs) => ({
                            startDate: headcountReqs.startDate,
                            endDate: headcountReqs.endDate,
                          })
                        )}
                      />
                    </div>
                  );
                })}
            </CollapseableSection>
          );
        })}
      </div>
      {headcountRequests.length === 0 && (
        <div style={{ margin: 50 }}>
          <Alert className={styles.footer_alert_message} color="dark">
            Please add at least one headcount to proceed
          </Alert>
        </div>
      )}
      <div className={styles.footer_btn_group}>
        <div className={styles.footer_btn_group_left}></div>
        <WizardButtonBar
          isContinueDisabled={
            headcountRequests.length === 0 ||
            validationState.isError ||
            disableRenderControls
          }
          continueMethod={props.next}
          backMethod={props.back}
          quitMethod={() => props.quitCcr(history)}
        >
          <UnityButton
            text="Reset Everything"
            color="inverted"
            onClick={() => props.resetAllHeadcounts()}
          />
          {validationState.isError && (
            <Alert className={styles.footer_alert_message} color="danger">
              {`Something is Wrong! ${validationState.errorMsg}`}
            </Alert>
          )}
        </WizardButtonBar>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  headcountRequests: getRenderableHeadcounts(state),
  currentlyEditingHeadcount: getIsEditingHeadcount(state),
  currentlyCreatingNew: getCurrentlyCreatingNew(state),
  currentCloneFromId: getCurrentCloneFromId(state),
});

const mapDispatchToProps = {
  createHeadcount: Actions.createHeadcount,
  resetAllHeadcounts: Actions.resetAllHeadcounts,
  quitCcr: quitCcr,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageHeadcounts);
